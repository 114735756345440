import { Component, EventEmitter, inject, Input, Output} from '@angular/core';
import { AssociationComplete } from 'src/app/shared/models/association.model';
import { SeatBuyerType, SeatCollection } from 'src/app/shared/models/availabilty/seat.model';
import { APP_CONFIG } from 'src/configuration/configuration';

@Component({
  selector: 'app-seat-selector',
  templateUrl: './seat-selector.component.html',
  styleUrl: './seat-selector.component.css'
})
export class SeatSelectorComponent {

  @Input()
  user!: AssociationComplete;

  @Input()
  userBuyerType!: string | null;

  @Input()
  customerCart!:  SeatCollection;

  @Input()
  isSelected!: boolean;

  @Input()
  isExchange!: boolean;

  @Output()
  selectUser: EventEmitter<AssociationComplete> = new EventEmitter<AssociationComplete>();

  @Output()
  remove:     EventEmitter<string>      = new EventEmitter<string>();

  @Output()
  openView:   EventEmitter<string>      = new EventEmitter<string>();

  @Output()
  update:     EventEmitter<any>         = new EventEmitter();
  
  
  protected cart:     any     = {};
  protected show:     boolean = true;
  protected currency: string  = inject(APP_CONFIG).general.currency;
  
  public get exchangeCart(): any[] {
    return Object.values(this.customerCart).flatMap(obj => Object.values(obj.seats_assigned_og!));
  }

  public changeUser(): void {
    this.selectUser.emit(this.user);
  }

  public removeSeat(id: string): void {
    this.remove.emit(id);
    this.updateCart();
  }

  public selectBuyerType(buyerType: SeatBuyerType, seatId: string): void {
    this.cart[seatId] = buyerType;
    this.updateCart();
  }

  public unselectBuyerType(id: string): void {
    delete this.cart[id];
    this.updateCart;
  }

  public openView3d(id: string):void {
    this.openView.emit(id)
  }

  /**
   * Muestra o esconde el selector de cantidades
   */
  public toggleSelector(): void {
    this.show = !this.show;
  }

  protected formatSeatName(originalId: string){
    let data: string[] = originalId.split('-')
    
    return !this.isExchange ? 
    `${data[0].slice(2)} | ${data[1]} | ${data[2]}` :
    `Section: ${data[0].slice(2)} | Row: ${data[1]} | Seat: ${data[2]}`
  }

  private checkCart(){
    let customerSeatsIds = Object.keys(this.customerCart),
        selectedSeatsId  = Object.keys(this.cart);
    
    const diference = selectedSeatsId.filter( id => !customerSeatsIds.includes(id));
    
    diference.length ? diference.forEach( key => delete this.cart[key]) : false;
  }

  private updateCart(){
    this.checkCart();
    this.update.emit(this.cart);
  }

}
