<div class="bg-white dark:bg-main rounded-xl p-3 shadow" >
    <!-- USER -->
   <div class="flex justify-between px-1 items-center w-full h-full" (click)="toggleSelector()">
        <p class="text-secondary dark:text-white font-semibold w-1/2">
            {{user.tdc_info.first_name}} {{user.tdc_info.last_name}}
        </p>
        
        <p class="flex items-center w-1/2 justify-end" *ngIf="priceScale">
            <span class="text-[0.65rem] opacity-50 mr-1 font-bold dark:text-gray-300 line-clamp-1">
                {{priceScale.name}}
            </span>
            <i [ngClass]="{'rotate-180': show}" class="transition-transform duration-200 ease-in">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-secondary dark:text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </i>
        </p>
    </div>
    
    <!-- PRICE SCALES | SECTIONS -->
    <div *ngIf="priceScale && show === true" class="mt-3">
        <div *ngFor="let price of priceScale.prices| keyvalue"
            class="flex justify-between px-[.30rem] items-center my-3 w-full">
            <div class="flex flex-col w-8/12">
                <span class="font-main font-bold text-darkgray text-sm dark:text-gray-200">
                {{price.value.name}} 
            </span>
            <span class="font-main font-bold text-gray-400 text-xs mr-5 w-4/12">
                {{price.value.price | currency:currency }}
            </span>
            </div>
            
            <div class="flex w-20 justify-between">
                <button class="button w-5 h-5 lg:w-6 lg:h-6 flex justify-center items-center dark:bg-white dark:text-secondary dark:hover:text-white dark:hover:bg-secondary" (click)="onSubstract(price.value.id)"> - </button>
                <span class="font-main dark:text-white font-medium text-gray-500"> {{this.cart()[user.associate_id][price.value.id].num_tickets}} </span>
                <button class="button w-5 h-5 lg:w-6 lg:h-6 flex justify-center items-center dark:bg-white dark:text-secondary dark:hover:text-white dark:hover:bg-secondary" (click)="onAdd(price.value.id)" [disabled]="disabled"> + </button>
            </div>
        </div>
    </div>
</div>