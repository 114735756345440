<div class="dark:bg-main rounded-xl p-3 shadow" [ngClass]="{'bg-main shadow-md shadow-secondary/50': isSelected, ' bg-white': !isSelected}" (click)="changeUser()">
    
    <!-- USER -->
    <div class="flex justify-between px-1 items-center w-full h-full cursor-pointer" (click)="toggleSelector()">
        <p class="text-secondary dark:text-white font-semibold w-1/2" [ngClass]="{'text-white': isSelected}">
            {{user.tdc_info.first_name}} {{user.tdc_info.last_name}} 
        </p>           
        <span *ngIf="userBuyerType" class="font-main font-medium text-gray-500" [ngClass]="{'text-white': isSelected}">
            {{userBuyerType}}
        </span>
    </div>

    <!-- SEATS NORMAL -->
    <ng-container *ngIf="!isExchange else exchangeSeats">
        <div class="p-3 mt-2 bg-gray-100 rounded-lg" *ngFor="let seat of customerCart | keyvalue">
            
            <!-- Seat Data -->
            <div class="flex w-full justify-between">
                
                <!-- Title of Seat -->
                <p class="font-medium text-gray-700 font-main">
                    {{formatSeatName(seat.value.originalId!)}}
                </p>
                
                <!-- Iconos Visor y Eliminar -->
                <div class="flex items-center gap-x-2">
                    <!-- Visor -->
                    <button class="viewSeat" (click)="openView3d(seat.key); $event.stopPropagation()">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18.147 15.965" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-[rgba(0,0,0,.4)] hover:text-secondary transition-colors">
                                <g id="_360-view" data-name="360-view" transform="translate(-23 -39)">
                                  <g id="Grupo_67" data-name="Grupo 67" transform="translate(24 40)">
                                    <path id="Trazado_201" data-name="Trazado 201" d="M24.873,43.055H26.4m0,0a1.527,1.527,0,1,1,0,3.055H24m2.4-3.055A1.527,1.527,0,1,0,26.4,40H24" transform="translate(-24 -40)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Trazado_202" data-name="Trazado 202" d="M39.711,120c0,2.893-3.518,5.237-7.856,5.237S24,122.893,24,120" transform="translate(-24 -111.272)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8"/>
                                    <path id="Trazado_203" data-name="Trazado 203" d="M83.491,40h-.873A2.619,2.619,0,0,0,80,42.619v1.746m3.928-.436v.436a1.746,1.746,0,0,1-1.746,1.746h-.436A1.746,1.746,0,0,1,80,44.364v-.436a1.746,1.746,0,0,1,1.746-1.746h.436A1.746,1.746,0,0,1,83.928,43.928Z" transform="translate(-73.89 -40)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    <path id="Trazado_204" data-name="Trazado 204" d="M136,43.055V41.746A1.746,1.746,0,0,1,137.746,40h.436a1.746,1.746,0,0,1,1.746,1.746v2.619a1.746,1.746,0,0,1-1.746,1.746h-.436A1.746,1.746,0,0,1,136,44.364Z" transform="translate(-123.78 -40)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  </g>
                                </g>
                            </svg>
                        </i>
                    </button>
    
                    <!-- Eliminación -->
                    <button class="eraseSeat" (click)="removeSeat(seat.key); $event.stopPropagation()">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14.331 17.552" class="w-4 h-4 text-[rgba(0,0,0,.4)] hover:text-secondary transition-colors">
                                <g id="delete" transform="translate(-23.5 -10.5)">
                                  <path id="Trazado_197" data-name="Trazado 197" d="M72,72h1.111v6.665H72Z" transform="translate(-43.556 -54.446)" stroke="currentColor" stroke-width="1"/>
                                  <path id="Trazado_198" data-name="Trazado 198" d="M108,72h1.111v6.665H108Z" transform="translate(-76.224 -54.446)" stroke="currentColor" stroke-width="1"/>
                                  <path id="Trazado_199" data-name="Trazado 199" d="M24,36v1.111h1.111V48.22a1.111,1.111,0,0,0,1.111,1.111h8.887A1.111,1.111,0,0,0,36.22,48.22V37.111h1.111V36Zm2.222,12.22V37.111h8.887V48.22Z" transform="translate(0 -21.778)" stroke="currentColor" stroke-width="1"/>
                                  <path id="Trazado_200" data-name="Trazado 200" d="M72,12h4.444v1.111H72Z" transform="translate(-43.556 -1)" stroke="currentColor" stroke-width="1"/>
                                </g>
                            </svg>
                        </i>
                    </button>
                </div>

            </div>
            
            <!-- Seat Buyer Types -->
            <div class="mt-1">
                <!-- BUYER TYPE LIST -->
                <ng-container *ngIf="!cart.hasOwnProperty(seat.key)">
                    <ul>
                        <li class="only:rounded-[15px_15px_15px_15px] first:rounded-[15px_15px_0_0] border-b p-2 last:border-b-0 last:rounded-[0_0_15px_15px] bg-white transition-colors duration-100 ease-in hover:bg-secondary hover:text-white" 
                            *ngFor="let buyerTypes of seat.value.buyer_types | keyvalue">
                            <div class="cursor-pointer w-full h-full" (click)="selectBuyerType(buyerTypes.value , seat.key)">
                                <p class="text-sm flex justify-between items-baseline">
                                    {{buyerTypes.value.name}}
                                    <span class="text-xs text-current/50">
                                        {{ buyerTypes.value.price | currency:currency }}
                                    </span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </ng-container>
               
                <!-- BUYER TYPE SELECTED -->
                <ng-container *ngIf="cart.hasOwnProperty(seat.key)">
                    <div class="bg-secondary text-white cursor-pointer rounded-[15px] p-2"
                     (click)="unselectBuyerType(seat.key)">
                        <p class="text-sm flex justify-between items-baseline">
                            {{cart[seat.key].name}}
                            <span class="text-xs text-current/50">
                                {{cart[seat.key].price | currency:currency}}
                            </span>
                        </p>
                    </div>
                </ng-container>
                
            </div>

        </div>    
    </ng-container>

    <!-- SEATS EXCHANGE -->
    <ng-template #exchangeSeats>
        <div class="pl-1 cursor-default">
            <p *ngIf="exchangeCart.length" class="text-sm" [ngClass]="isSelected ? 'text-white' : 'text-gray-500'">
                Seats:
            </p>
            <div *ngFor="let seat of exchangeCart | keyvalue" [ngClass]="isSelected ? 'text-white' : 'text-secondary'">
                {{formatSeatName(seat.value)}}
            </div>
        </div>
    </ng-template>

</div>