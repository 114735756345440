import { SeatCollection, SeatData } from "src/app/shared/models/availabilty/seat.model";
import { Cart, SeatCart } from "src/app/shared/models/cart.model"
import { FamilyEnclosure } from "src/app/shared/models/family-enclosure.model";
import { AppConfig } from "src/configuration/configuration";
import { environment } from "src/environments/environment";

/**
 * ### Family Enclosure Validation
 * 
 * ##### Método Helper para validar la lógica de Family Enclosure
 * 
 * Esta función se encarga de exportar métodos de validación para la lógica de Family Enclosure tanto para el 
 * proceso clásico como el de Exchange. 
 * (Tanto en Best Available como en Select A Seat)
 * 
 * ##### Métodos
 * 
 * @function initFamilyEnclosureValidator
 * @returns {void}              - Inicializa las variables necesarias para la validación de Family Enclosure. 
 * 
 * @function hasSeatsOnFamilyArea
 *  @param {SeatCart} cart      - Carrito de asientos.
 *  @param {boolean} isExchange - Indica si el proceso es de Exchange.
 *  @returns {boolean}          - Devuelve true si hay asientos en una zona familiar.
 * 
 * @function validateFamilyEnclosure
 *  @param {SeatCart} cart      - Carrito de asientos.
 *  @param {boolean} isExchange - Indica si el proceso es de Exchange.
 *  @returns {boolean}          - Devuelve true si se cumple la lógica de Family Enclosure.
 */
export const familyEnclosureValidation = () => {

    let familyEnclosure!:       FamilyEnclosure[''],
        familySections!:        string[],
        familyEnclosureIds!:    {[x:string]: number[]};
    
    /**
     * ### initFamilyEnclosureValidator
     * 
     * Inicializa las variables necesarias para la validación de Family Enclosure.
     * 
     * @param {boolean} isExchange 
     */
    const initFamilyEnclosureValidator = (isExchange: boolean): void => {
        
        const familyListKey: keyof FamilyEnclosure['']['family_buyer_types'] = isExchange ? "exchange" : "default";
       
        familyEnclosure       = environment.familyEnclosure![getClientKey()];
        familySections        = familyEnclosure?.list_family_enclosure_sections;
        familyEnclosureIds    = {
            familyListIds:       familyEnclosure?.family_buyer_types[familyListKey].family_enclosure_buyer_type_id, 
            familyListIdsJunior: familyEnclosure?.family_buyer_types[familyListKey].family_enclosure_buyer_type_id_junior,
            familyListIdsSenior: familyEnclosure?.family_buyer_types[familyListKey].family_enclosure_buyer_type_id_senior
        }
        
    };

    /**
     * ### hasSeatsOnFamilyArea
     * 
     * Comprueba si hay asientos en una zona familiar.
     * Para ello, recorre todos los carritos (usuarios) y comprueba cada uno de los asientos asignados.
     * Finalmente, comprueba si el id de la sección del asiento está en la lista de secciones familiares, 
     * si es asi, devuelve true.
     * 
     * @param { SeatCart } cart 
     * @param { boolean }  isExchange 
     * @returns void
     */
    const hasSeatsOnFamilyArea         = (cart: SeatCart, isExchange: boolean ): boolean => {
        
        let hasSeatsOnFamilyArea: boolean = false;

        // Accedemos a las llaves del customer cart (los ids de los usuarios) e iteramos
        for (const userKey of Object.keys(cart)) {

            // Accedemos al carrito del usuario ([holdCode]: SeatData)
            const holdCodeCart: SeatCollection = cart[userKey as any];
    
            // Iteramos sobre los hold codes del carrito del usuario
            for (const key of Object.keys(holdCodeCart)) {
    
                // Obtenemos los asientos asignados del hold code y comprobamos si alguno de los asientos está en una zona familiar
                const seatData: SeatData = holdCodeCart[key];

                // Inicializamos la variable que indica si el asiento está en una zona familiar
                let seat!: boolean;

                // Si es Exchange, obtenemos los ids de las secciones y buscamos, si no, leemos la propiedad section del asiento.
                if (isExchange) {
                    
                    const sectionsId: string[] = seatData.seats_assigned!.map((seat: string) => seat.split('-')[0]);                    
                    
                    seat = sectionsId.find((sectionId: string) => familySections.includes(sectionId)) !== undefined;

                } else {
                    seat = seatData.hasOwnProperty('section') ? familySections.includes(seatData.section!) : false;
                }
                
                // Si hay asientos en una zona familiar, salimos
                if (seat) {
                    hasSeatsOnFamilyArea = true;
                    break;
                }
            }
    
            // Si hay asientos en una zona familiar, salimos
            if (hasSeatsOnFamilyArea) {
                break;
            }
  
        }
  
        return hasSeatsOnFamilyArea;
    }

    
    /**
     * ### validateFamilyEnclosure
     * 
     * Método que aplica las lógicas de Family Enclosure para validar si se cumplen las reglas de la zona familiar.
     * Estas reglas están explicadas en el ticket https://mmcbcn.atlassian.net/browse/IT-2225
     * En resumen, se pueden añadir adultos en función de la cantidad de juniors y seniors que haya en el carrito.
     * 
     * Para ello, se crea un objeto contador que almacena la cantidad de cada tipo de asiento (junior, senior, adult).
     * Se itera sobre el carrito y se incrementa el contador según el tipo de asiento. Si es Exchange, se incrementa 
     * según la cantidad de asientos asignados, si no, se incrementa según el número de tickets.
     * 
     * Si no tiene la propiedad section, se incrementa el contador según el id del asiento.
     * Finalmente, se suman los juniors, seniors y adultos y se comprueba que la cantidad de adultos no sea mayor a la permitida y 
     * se devuelve true si se cumple la lógica de Family Enclosure.
     * 
     * @param cart 
     * @param isExchange 
     * @returns 
     */
    const validateFamilyEnclosure      = (cart: SeatCart | Cart, isExchange: boolean):  boolean => {

        const { familyListIds, familyListIdsJunior, familyListIdsSenior } = familyEnclosureIds;
        const counter: any     = {};
        
        let   isValid: boolean = false;

        familyListIds.forEach(id => counter[id] = 0)
        familyListIdsJunior.forEach(id => counter[id] = 0)
        familyListIdsSenior.forEach(id => counter[id] = 0)

        for(let userCart of Object.keys(cart)){
            
            const holdCodeCart = cart[userCart as any];

            for(const [key,value] of Object.entries(holdCodeCart)){

                if(isExchange){
                    counter[key] += value.seats_assigned.length;
                }else{
                    value.hasOwnProperty('section') ? 
                        counter[key] += value.num_tickets! :
                        counter[value.id] += 1;
                }

            }

        }

        /**
         * Aplicamos logicas de Family Enclosure:
         * Validamos que entre todos los usuarios cumplan las reglas de enclosure family https://mmcbcn.atlassian.net/browse/IT-2225
         *
         * - Por cada junior pueden añadir 2 adults.
         * - Por cada senior pueden añadir 1 adult.
         * - Se puede combinar entre si: 2 junior + 2 senior => 6 adults allowed.
         *
         * Para ello, hacemos lo siguiente:
         * 1. Sumamos todos los Juniors del Counter.
         * 2. Sumamos todos los Seniors del Counter.
         * 3. Sumamos todos los Adults del Counter.
         * 4. Verificamos que la cantidad de adults NO este por encima de la permitida usando las reglas del Family Enclosure
         */
        
        // Sumamos Juniors    
        let totalJuniors:  number = familyListIdsJunior.reduce((acc, val) => acc += counter[val], 0);
        let allowedAdults: number = totalJuniors * 2;

        // Sumamos Seniors
        let totalSeniors:  number = familyListIdsSenior.reduce((acc, val) => acc += counter[val], 0);
        allowedAdults += totalSeniors;

        // Sumamos Adults
        let totalAdults:   number = familyListIds.reduce((acc, val) => acc += counter[val], 0);

        // Verificamos que la cantidad de adultos no este por encima de la permitida
        if(totalAdults <= allowedAdults){
            isValid = true;
        }

        return isValid;
    }

    /**
     * Función que mediante el App Config y el nombre del cliente, devuelve la key del cliente para
     * acceder a su configuración en el archivo de configuración u environment.
     * 
     * @returns {string} - Devuelve el nombre del cliente.
     */
    function getClientKey(): string {
        switch(AppConfig.useValue.general.clientName){
            case 'Chelsea FC':
                return 'chelseafc';
            default:
                return 'local';
        }
    }

    return {
        initFamilyEnclosureValidator,
        hasSeatsOnFamilyArea,
        validateFamilyEnclosure
    }

}