<div class="bg-white dark:bg-gray-900 h-full overflow-y-auto w-full flex flex-col lg:justify-between">
    
    <!-- MAIN COMPONENT -->
    <ng-container *ngIf="associations() else loader">
        
        <app-back-button/>

        <!-- FRIENDS LIST  -->
        <div class="container mx-auto p-4 my-6 lg:mt-2 lg:mb-28 lg:items-center ">            
            
            <app-card [data]="entity()!" [showButton]="false" [showText]="false">
                
                <ng-container *ngIf="isEvent else isPackage">

                    <app-friends-list 
                     [user]="user" 
                     [associations]="associationsData()" 
                     [selectOne]="isMembership()" 
                     (onSelect)="updateSelected($event)"
                     /> 

                </ng-container>

                <ng-template #isPackage>

                    <app-friends-list 
                     [user]="user" 
                     [associations]="associationsData()"  
                     (onSelect)="updateSelected($event)"
                     />  

                </ng-template>
                    
            </app-card>
                
        </div>

    </ng-container>
    
    <!-- LOADER -->
    <ng-template #loader>
        <div class="h-screen lg:h-full w-full flex items-center justify-center">           
            <app-loader/>
        </div>
    </ng-template>
    
    <!-- FOOTER -->
    <app-footer 
        buttonText="Continue" 
        [textDetail]="quantitySelected + ' selected'"
        [showFooter]="quantitySelected > 0"
        [disableButton]="quantitySelected === 0"
        (footerEvent)="nextStep()"
    />
    
</div>